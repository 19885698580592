import logo from "./logo.svg";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CssBaseline } from "@mui/material";

import "./App.css";
import Dashboard from "./pages/Dashboard";

Sentry.init({
  dsn: "https://24f83008cdd944b18ebd70dd3189c99b@o4504291107209216.ingest.sentry.io/4504291112386560",
  integrations: [new BrowserTracing()],
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        title: "Desculpe, ocorreu um erro",
        subtitle:
          "Nossa equipe foi notificada e resolveremos o problema o mais rápido possível",
        subtitle2: "Você pode nos ajudar a resolver o problema?",
        labelName: "Seu nome",
        labelComments: "O que aconteceu?",
        labelClose: "Fechar",
        labelSubmit: "Enviar",
        errorGeneric: "Ocorreu um erro. Por favor, tente novamente mais tarde.",
        errorFormEntry: "Por favor, preencha todos os campos corretamente",
        successMessage: "Obrigado por nos ajudar a resolver o problema!",
      });
    }
    return event;
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

function App() {
  const methodDoesNotExist = () => {
    throw new Error("This method does not exist");
  };

  return (
    <Sentry.ErrorBoundary
      fallback={<>{"An error has occurred"}</>}
      showDialog
      beforeCapture={() => {
        Sentry.setExtra("info", "This is extra info");
        Sentry.setTag("location", "Component: App");
      }}
    >
      <>
        <CssBaseline />
        <div className="App">
          <Dashboard />
        </div>
      </>
    </Sentry.ErrorBoundary>
  );
}

export default App;
